import { styled } from '@slal/ui';
import { P } from '@slal/ui/typography';
import { FieldSet, Notification, relatedFieldIndent } from '~/components';

export const StyledNotification = styled(Notification)`
    margin-left: ${relatedFieldIndent};
`;

export const LumpSumHelpText = styled(P)`
    color: ${({ theme: { colors } }) => colors.neutral__dark__20};
    font-size: 14px;
    margin-bottom: ${({ theme: { space } }) => space[6]};
`;

export const StyledFieldSet = styled(FieldSet)`
    margin-bottom: ${({ theme: { space } }) => space[4]};
`;
