import { PDFIcon } from '@slal/ui/icons';
import { AnchorProps } from '@slal/ui/navigation';
import { StyledDocumentLink } from './styles';

export const DocumentLink = ({ children, ...otherProps }: AnchorProps) => {
    return (
        <StyledDocumentLink target="_blank" {...otherProps}>
            <PDFIcon />
            {children}
        </StyledDocumentLink>
    );
};
