import { isEmpty } from 'lodash';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationContext, useApplication } from '~/hooks';
import {
    ApplicationContextValues,
    ApplicationFormKeys,
    ApplicationPensionValuesInput,
    ApplicationSchema,
} from '~/types';
import { getPensionValues } from '~/utils';

const getPensionValuesInput = (application: Partial<ApplicationSchema>): ApplicationPensionValuesInput => {
    const { regularIncome, ...otherProps } = application;

    if (!regularIncome || isEmpty(regularIncome)) return otherProps;
    return {
        ...otherProps,
        regularIncome:
            !regularIncome || isEmpty(regularIncome)
                ? undefined
                : (regularIncome as ApplicationPensionValuesInput['regularIncome']),
    };
};

const getValues = (getSession: (formKey: ApplicationFormKeys) => ApplicationSchema | undefined) => {
    const withdrawalOptions = getSession(ApplicationFormKeys.WITHDRAWAL_OPTIONS);
    const pensionTransfer = getSession(ApplicationFormKeys.PENSION_TRANSFER);
    const personalInformation = getSession(ApplicationFormKeys.PERSONAL_INFORMATION);
    const lumpSumOptions = getSession(ApplicationFormKeys.LUMP_SUM_OPTIONS);
    const regularIncome = getSession(ApplicationFormKeys.REGULAR_INCOME);
    const investmentOption = getSession(ApplicationFormKeys.INVESTMENT_OPTIONS);

    const pensionValuesInput = getPensionValuesInput({
        ...lumpSumOptions,
        ...pensionTransfer,
        ...regularIncome,
        ...withdrawalOptions,
    });

    return {
        investmentOption: investmentOption?.investment?.option,
        pensionValues: getPensionValues(pensionValuesInput),
        personalInformation: personalInformation?.personalInformation,
        withdrawalOption: withdrawalOptions?.withdrawal?.option,
    };
};

export const ApplicationContextProvider = ({ children }: PropsWithChildren) => {
    const { getSession } = useApplication();
    const { pathname } = useLocation();
    const [application, setApplication] = useState<ApplicationContextValues>(getValues(getSession));

    const refreshApplication = useCallback(() => {
        setApplication(getValues(getSession));
    }, [getSession, setApplication]);

    useEffect(() => {
        refreshApplication();
    }, [pathname, refreshApplication]);

    return (
        <ApplicationContext.Provider value={{ ...application, refreshApplication }}>
            {children}
        </ApplicationContext.Provider>
    );
};
