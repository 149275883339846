import { styled } from '@slal/ui';
import { Modal, ModalBody, ModalFooter } from '@slal/ui/modal';

export const StyledModal = styled(Modal)`
    > div:last-child {
        max-height: 90vh;
    }
`;

export const StyledModalBody = styled(ModalBody)`
    fieldset {
        margin: 0;
    }
`;

export const StyledModalFooter = styled(ModalFooter)`
    justify-content: space-between;
`;
