import { Button } from '@slal/ui/buttons';
import { Container } from '@slal/ui/layout';
import { Modal, ModalBody, ModalFooter } from '@slal/ui/modal';
import { FormProvider, useForm, useWatch } from '@slal/ui/react-hook-form/core';
import { CheckboxField } from '@slal/ui/react-hook-form/fields';
import { Article, P } from '@slal/ui/typography';
import { FormEventHandler, useCallback } from 'react';
import { Notification, StyledForm } from '~/components';
import { APPLICATION_KEY, useCreatePensionConsolidationMutation } from '~/hooks';
import { PensionConsolidationFormInputs } from '~/types';
import { PensionConsolidationDisclaimer, StyledCheckboxLabelContent, StyledField } from './styles';

const PensionConsolidationLabel = () => {
    return (
        <StyledCheckboxLabelContent markdown tag="span">
            I would like Standard Life to contact me about combining my pensions. Read our [Privacy
            Policy](https://www.standardlife.co.uk/privacy).
        </StyledCheckboxLabelContent>
    );
};

export const PensionConsolidationModal = ({
    isModalOpen,
    closeModal,
}: {
    isModalOpen: boolean;
    closeModal: () => void;
}) => {
    const form = useForm<PensionConsolidationFormInputs>({
        defaultValues: { consent: false },
    });

    const { createPensionConsolidationRequest, data, error, loading } = useCreatePensionConsolidationMutation();

    const consent = useWatch({
        control: form.control,
        name: 'consent',
    });

    const handleClose = useCallback(() => {
        form.reset();
        closeModal();
    }, [closeModal, form]);

    const handleSubmit: FormEventHandler = useCallback(
        event => {
            event.preventDefault();
            if (consent) {
                createPensionConsolidationRequest({
                    variables: {
                        input: {
                            application: sessionStorage.getItem(APPLICATION_KEY),
                        },
                    },
                });
            } else {
                handleClose();
            }
        },
        [createPensionConsolidationRequest, consent, handleClose]
    );

    return (
        <Modal isOpen={isModalOpen} onClose={handleClose} title="Combine my other pensions">
            <FormProvider {...form}>
                <Container>
                    {!data?.success ? (
                        <StyledForm onSubmit={handleSubmit}>
                            <ModalBody>
                                <Article>
                                    <P>
                                        Combining your pensions can make things easier to manage and help you keep track
                                        of your retirement savings. Transferring won't be right for everyone. You can do
                                        this online or our pension experts are happy to help.
                                    </P>

                                    <StyledField errorFor="consent">
                                        <CheckboxField label={<PensionConsolidationLabel />} name="consent" />
                                    </StyledField>

                                    <PensionConsolidationDisclaimer>
                                        A pension is an investment. Its value can go down as well as up and could be
                                        worth less than was paid in.
                                    </PensionConsolidationDisclaimer>

                                    {error && (
                                        <Notification level="danger">
                                            An error has occurred, please try again later.
                                        </Notification>
                                    )}
                                </Article>
                            </ModalBody>

                            <ModalFooter>
                                <Button type="submit" variant="solid" color="secondary" submitting={loading}>
                                    Submit
                                </Button>
                            </ModalFooter>
                        </StyledForm>
                    ) : (
                        <>
                            <ModalBody>
                                <Notification level="success">Thanks, We'll be in touch.</Notification>
                            </ModalBody>

                            <ModalFooter>
                                <Button variant="outline" color="secondary" onClick={closeModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Container>
            </FormProvider>
        </Modal>
    );
};
