import { styled } from '@slal/ui';
import { Field } from '@slal/ui/react-hook-form/fields';
import { P, Text } from '@slal/ui/typography';

export const PensionConsolidationDisclaimer = styled(P)`
    padding: ${({ theme }) => theme.space[6]} 0 ${({ theme }) => theme.space[3]} 0;
`;

export const StyledField = styled(Field)`
    div {
        display: flex;
        align-items: flex-start;
    }

    input {
        margin-top: 3px;
    }

    margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const StyledCheckboxLabelContent = styled(Text)`
    font-weight: ${({ theme }) => theme.fonts.sansSerif.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    line-height: ${({ theme }) => theme.lineHeights.copy};
`;
